export const userModule = {
  state: {
    currentUser: false,
    currentUserToken: false,
    currentUserInfo: {
      role: null,
    },
  },
  mutations: {
    LOGIN_USER(state) {
      state.currentUser = true;
    },
    SET_AUTH_TOKEN(state, payload) {
      state.currentUser = payload;
    },
    SET_USER_ROLE(state, role) {
      state.currentUserInfo.role = role;
    },
    CLEAR_USER_STATE(state) {
      state.currentUser = false;
      state.currentUserInfo = {
        role: null,
      };
      state.currentUserToken = false;
    },
  },
  actions: {
    loginUser({ commit }) {
      commit("LOGIN_USER");
    },
    setAuthToken({ commit }, token) {
      commit("SET_AUTH_TOKEN", token);
    },
    setCurrentUserRole({ commit }, role) {
      commit("SET_USER_ROLE", role);
    },
    clearUserState({ commit }) {
      commit("CLEAR_USER_STATE");
    },
  },
};
