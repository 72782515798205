<template>
  <div class="center-container">
    <CContainer class="pt-5 center-container">
      <h1 class="text-center">SocialFeed v2</h1>
      <CForm @submit.prevent="submitLoginForm">
        <div class="mb-3">
          <CFormLabel for="email">Email adresa</CFormLabel>
          <CFormInput
            :disabled="!isLoaded"
            type="email"
            id="email"
            v-model="email"
            aria-describedby="emailHelp"
            required
          />
        </div>
        <div class="mb-3">
          <CFormLabel :disabled="!isLoaded" for="password">Lozinka</CFormLabel>
          <CFormInput
            type="password"
            id="password"
            v-model="password"
            required
          />
        </div>
        <div class="d-grid gap-2">
          <CButton :disabled="!isLoaded" type="submit" color="primary">
            <CSpinner
              v-if="!isLoaded"
              component="span"
              size="sm"
              aria-hidden="true"
            />Uloguj se
          </CButton>
        </div>
      </CForm>
    </CContainer>
    <CToast
      v-if="loginError"
      :autohide="true"
      color="danger"
      class="text-white align-items-center login-error-box"
    >
      <div class="d-flex">
        <CToastBody>{{ errorMessage }}</CToastBody>
        <CToastClose class="me-2 m-auto" white />
      </div>
    </CToast>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppFooter from "../layout/Footer.vue";
import { loginUser } from "../helpers/apiCalls";
import jwt_decode from "jwt-decode";

import {
  CForm,
  CSpinner,
  CButton,
  CFormLabel,
  CFormInput,
  CContainer,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/vue";

export default {
  components: {
    CFormLabel,
    CForm,
    CContainer,
    CFormInput,
    CButton,
    CToast,
    CToastBody,
    CToastClose,
    CSpinner,
    AppFooter,
  },
  data: function () {
    return {
      email: null,
      password: null,
      loginError: false,
      errorMessage: "",
      isLoaded: false,
    };
  },
  mounted() {
    if (this.$route.query.logout) {
      this.$cookies.remove(`${process.env.VUE_APP_LOGIN_COOKIE_NAME}`);
      this.$store.dispatch("clearUserState");
      this.$store.dispatch("clearUsersList");
    }
    document.title = "SocialFeed v2 - Login";
    this.isLoaded = true;
  },
  methods: {
    async submitLoginForm() {
      this.isLoaded = false;
      try {
        const {
          data,
          data: { access_token, expires_in },
        } = await loginUser(this.email, this.password);
        const { role } = jwt_decode(access_token);
        if (data && role) {
          this.$cookies.set(
            `${process.env.VUE_APP_LOGIN_COOKIE_NAME}`,
            access_token,
            expires_in
          );
          this.$store.dispatch("loginUser");
          this.$store.dispatch("setAuthToken", access_token);
          const decoded = jwt_decode(
            this.$cookies.get(`${process.env.VUE_APP_LOGIN_COOKIE_NAME}`)
          );
          this.$store.dispatch("setCurrentUserRole", decoded.role);
          this.$router.push(`/dashboard/${decoded.role}`);
        }
      } catch (error) {
        this.isLoaded = true;
        this.errorMessage = error.message;
        this.loginError = !this.loginError;
      }
    },
  },
};
</script>
<style scoped>
.login-error-box {
  position: fixed;
  bottom: 30px;
  z-index: 9;
  right: 10px;
}
.spinner-wrapper {
  position: fixed;
  z-index: 999;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.center-container {
  max-width: 500px;
  margin: 0 auto;
}
</style>
