<template>
  <CHeader>
    <CContainer fluid>
      <CHeaderBrand href="#"
        >SocialFeed - v2
        <span v-if="$route.params.role === 'administrator'"
          >/ Admin</span
        ></CHeaderBrand
      >
      <CHeaderToggler @click="visible = !visible" />
      <CCollapse class="header-collapse" :visible="visible">
        <CHeaderNav>
          <CNavItem>
            <router-link to="/login?logout=true"
              ><CNavLink href="#" active> Odjavi se </CNavLink></router-link
            >
          </CNavItem>
        </CHeaderNav>
      </CCollapse>
    </CContainer>
  </CHeader>
</template>

<script>
import {
  CHeader,
  CHeaderBrand,
  CHeaderToggler,
  CCollapse,
  CHeaderNav,
  CNavItem,
  CNavLink,
  CContainer,
} from "@coreui/vue";
export default {
  components: {
    CHeader,
    CHeaderBrand,
    CHeaderToggler,
    CCollapse,
    CHeaderNav,
    CNavItem,
    CContainer,
    CNavLink,
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>
