import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Slider from "../views/Slider.vue";
import PageNotFound from "../views/PageNotFound.vue";
const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";

const protectedRoute = (next) => {
  if (getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME)) {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  { path: "/:pathMatch(.*)*", component: PageNotFound, name: "404" },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard/:role",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      protectedRoute(next);
    },
  },
  {
    path: "/slider/:customerId",
    name: "Slider",
    component: Slider,
    beforeEnter: (to, from, next) => {
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
