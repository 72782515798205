<template>
  <CContainer class="mt-5">
    <div class="center-container">
      <span v-if="!isLoaded">
        <h1 class="text-center">Učitavanje...</h1>
      </span>
      <CListGroup v-else>
        <div
          v-for="user in usersListFromState.users"
          :key="user.id"
          class="my-1"
        >
          <CListGroupItem
            :class="{ activeTab: user.visible }"
            @click="
              () => {
                user.visible = !user.visible;
              }
            "
            ><p class="text-center m-0 fw-bold disable-select">
              {{ user.name }}
            </p></CListGroupItem
          >
          <CCollapse :visible="user.visible">
            <CCard class="mt-3">
              <CCardBody>
                <div class="row">
                  <div class="col d-grid">
                    <CButton color="primary">Ažuriraj informacije</CButton>
                  </div>
                  <div class="col d-grid">
                    <CButton
                      class="text-light"
                      color="danger"
                      @click="() => deleteUserLocal(user.id)"
                      >Obriši nalog</CButton
                    >
                  </div>
                </div></CCardBody
              >
            </CCard>
          </CCollapse>
        </div>
        <CButton
          v-if="loadMoreUsersState"
          class="mt-5"
          color="primary"
          @click="loadMoreUsers"
          >Učitaj još korisnika</CButton
        >
        <CButton class="mt-1" color="primary" @click="openModal = true"
          >Dodaj novog korisnika</CButton
        >
      </CListGroup>
    </div>
  </CContainer>

  <CModal
    scrollable
    @closeModal="closeModalParent"
    alignment="center"
    :visible="openModal"
    @close="
      () => {
        openModal = false;
      }
    "
  >
    <CModalHeader>
      <CModalTitle>Dodaj novog korisnika</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <RegisterUserForm />
    </CModalBody>
    <CModalFooter>
      <CButton
        color="secondary"
        class="text-light"
        @click="
          () => {
            openModal = false;
          }
        "
      >
        Zatvori
      </CButton>
    </CModalFooter>
  </CModal>
</template>

<script>
// import { useCookies } from "vue3-cookies";
import RegisterUserForm from "../RegisterUserForm.vue";
import { ref, onMounted, computed } from "vue";
import { loadUsers, loadMoreusers, deleteUser } from "../../helpers/apiCalls";
import { useStore } from "vuex";

import {
  CListGroup,
  CListGroupItem,
  CContainer,
  CCollapse,
  CCard,
  CButton,
  CCardBody,
  CModal,
  CModalFooter,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/vue";
export default {
  components: {
    CListGroupItem,
    CListGroup,
    CContainer,
    CCollapse,
    CCard,
    CCardBody,
    CButton,
    CModal,
    CModalFooter,
    CModalBody,
    CModalHeader,
    CModalTitle,
    RegisterUserForm,
  },
  emits: ["closeModal"],
  setup() {
    document.title = "SocialFeed v2";
    const visibleListItem = ref(false);
    const isLoaded = ref(false);
    let openModal = ref(false);
    const usersList = ref([]);
    const store = useStore();
    const loadMoreUsersState = ref(false);
    let pageNum = ref(2);
    // const { cookies } = useCookies();

    const getCookieValue = (name) =>
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      "";
    const closeModalParent = () => (openModal = false);
    const fetchUsers = async () => {
      try {
        const {
          data: { data, next_page_url },
        } = await loadUsers(
          getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME)
        );
        usersList.value = data;
        store.dispatch("setUsersList", data);
        isLoaded.value = true;
        if (next_page_url) {
          loadMoreUsersState.value = true;
        }
      } catch (e) {
        isLoaded.value = false;
      }
    };
    const loadMoreUsers = async () => {
      try {
        const {
          data: { data },
          data: { next_page_url },
        } = await loadMoreusers(
          getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME),
          pageNum.value
        );
        store.dispatch("addNewsUsers", data);
        if (!next_page_url) {
          loadMoreUsersState.value = false;
        }
      } catch (e) {
        isLoaded.value = false;
      }
      pageNum.value++;
    };
    const deleteUserLocal = async (id) => {
      await deleteUser(id);
      store.dispatch("removeUserFromList", id);
    };
    onMounted(() => {
      if (store.state.users.users.length < 1) {
        fetchUsers();
      } else if (process.env.NODE_ENV === "development") {
        fetchUsers();
        store.dispatch("clearUsersList");
      }
    });
    return {
      // cookies,
      deleteUserLocal,
      pageNum,
      loadMoreUsers,
      loadMoreUsersState,
      closeModalParent,
      visibleListItem,
      isLoaded,
      openModal,
      usersList,
      usersListFromState: computed(() => store.state.users),
    };
  },
};
</script>
<style>
.center-container {
  max-width: 500px;
  margin: 0 auto;
  cursor: pointer;
}
.activeTab {
  border: 1px solid rgb(128, 9, 9) !important;
}
.disable-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
