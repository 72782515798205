export const slideModule = {
  state: {
    slideTimer: 6000,
  },
  mutations: {
    UPDATE_SLIDER_TIME(state, time) {
      state.slideTimer = time;
    },
    RESET_TO_DEFAULT(state) {
      state.slideTimer = 6000;
    },
  },
  actions: {
    updateSlideTimer({ commit }, time) {
      commit("UPDATE_SLIDER_TIME", time);
    },
    resetToDefault({ commit }) {
      commit("RESET_TO_DEFAULT");
    },
  },
};
