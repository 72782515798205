export const adminUsersModule = {
  state: {
    users: [],
  },
  mutations: {
    SET_LIST_OF_USERS(state, usersList) {
      usersList.map((singleUser) => {
        state.users.push({ ...singleUser, visible: false });
      });
    },
    ADD_NEW_USER_TO_LIST(state, newUser) {
      state.users.push(newUser);
    },
    CLEAR_USERS_STATE(state) {
      state.users = [];
    },
    ADD_NEW_USERS(state, usersData) {
      usersData.map((user) => {
        state.users.push(user);
      });
    },
    REMOVE_USER_FROM_LIST(state, userId) {
      state.users = state.users.filter((user) => user.id !== userId);
    },
  },
  actions: {
    setUsersList({ commit }, users) {
      commit("SET_LIST_OF_USERS", users);
    },
    addNewUserToList({ commit }, user) {
      commit("ADD_NEW_USER_TO_LIST", user);
    },
    clearUsersList({ commit }) {
      commit("CLEAR_USERS_STATE");
    },
    addNewsUsers({ commit }, users) {
      commit("ADD_NEW_USERS", users);
    },
    removeUserFromList({ commit }, userId) {
      commit("REMOVE_USER_FROM_LIST", userId);
    },
  },
};
