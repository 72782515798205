<template>
  <footer class="mt-5">
    <p class="text-center">SocialFeed v2 - {{ year }}</p>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
