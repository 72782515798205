<template>
  <div v-if="!newSlides" class="fetch-slides"><CSpinner class="spinner" /></div>
  <div v-else class="linear-bg">
    <header class="linear-header">
      <div class="logo">
        <img
          :src="domainUrl + singleProfileInfo[0].logo.properties.thumbnail"
        />
      </div>
      <div class="app-logo" @click="enableFullScreen">
        <h1>Social<span>Feed</span></h1>
      </div>
    </header>
    <div class="slide-wrapper--grid">
      <div class="main-slider">
        <div v-if="fakeSpinner === true" class="fetch-slides--fake">
          <img
            v-if="newSlides"
            :src="domainUrl + singleProfileInfo[0].logo.properties.thumbnail"
            alt=""
          />
        </div>
        <vueper-slides
          v-if="renderComponent"
          fade
          ref="myVueperSlides"
          :visible-slides="1"
          :arrows="false"
          :bullets="false"
          @slide="logEvents('slide', $event)"
          :infinite="true"
          :touchable="false"
        >
          <vueper-slide v-for="slide in printArr" :key="slide.id"
            ><template #content>
              <span v-if="slide.type == 'IMAGE' || slide.link === null">
                <img
                  v-if="slide.link !== null"
                  :src="slide.link"
                  alt=""
                  class="slider-image"
                />
                <img
                  v-else
                  class="slider-image"
                  :src="domainUrl + '/' + slide.featured_image.slug"
                />
              </span>
              <span v-else-if="slide.type == 'VIDEO'">
                <vue3-video-player
                  muted
                  class="videos-reset"
                  :src="slide.link"
                ></vue3-video-player> </span></template
          ></vueper-slide>
        </vueper-slides>
      </div>
      <div class="flex-box">
        <div class="small-slider">
          <div class="carousel__slide">
            <img
              :src="domainUrl + singleProfileInfo[0].logo.properties.thumbnail"
              alt=""
              class="object-logo"
            />
            <h2>{{ singleProfileInfo[0].name }}</h2>
            <p>{{ singleProfileInfo[0].about }}</p>
          </div>
        </div>
        <div class="hashtag-info-area">
          <h1>Želiš da se tvoja objava nađe ovde?</h1>
          <p>Tagujte nas</p>
          <h2>#{{ singleProfileInfo[0].hashtag_name }}</h2>
        </div>
      </div>
    </div>
    <div class="created-by">
      <p>Aplikaciju omogućili W3 Lab & Z1 Tech</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { CSpinner } from "@coreui/vue";
import { fetchSlideItems } from "../helpers/apiCalls";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default defineComponent({
  name: "Slider",
  components: {
    CSpinner,
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      isSlideLoad: false,
      newSlides: false,
      fakeSpinner: false,
      singleProfileInfo: {},
      isFull: false,

      instaArr: [],
      customArr: [],
      repeat: 3,
      arr1: [],
      arr2: [],
      printArr: [],
      i: 1,
      renderComponent: true,
    };
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.i = 0;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async logEvents() {
      if (this.i === this.printArr.length) {
        this.fakeSpinner = true;
        const sliderId = this.$route.params.customerId;
        const { data } = await fetchSlideItems(sliderId);
        this.organizeSliders(data);
        this.forceRerender();
        setTimeout(() => {
          this.fakeSpinner = false;
          this.$refs.myVueperSlides.goToSlide(0);
        }, 1000);
      } else {
        this.i++;
      }
    },
    enableFullScreen() {
      this.isFull = !this.isFull;
      if (this.isFull) {
        document.body.webkitRequestFullScreen();
      } else {
        document.webkitExitFullscreen();
      }
    },
    organizeSliders(data) {
      this.printArr = [];
      this.instaArr = [];
      this.customArr = [];
      this.arr1 = [];
      this.arr2 = [];

      data.posts.map((item) => {
        if (item.repeat === null) {
          this.instaArr.push(item);
        } else {
          this.customArr.push(item);
        }
      });

      if (this.instaArr.length >= this.customArr.length) {
        this.arr1 = this.instaArr;
        this.arr2 = this.customArr;
      } else {
        this.arr1 = this.customArr;
        this.arr2 = this.instaArr;
      }

      let n = 0;
      let a =
        this.arr1.length + Math.floor(this.arr1.length / (this.repeat - 1));
      let b = this.repeat * this.arr2.length;

      if (a < b) {
        n = b;
      } else {
        n = a;
      }

      let index1 = 0;
      let index2 = 0;

      for (let i = 1; i <= n; i++) {
        if (i % this.repeat === 0) {
          if (index2 < this.arr2.length) {
            this.printArr[i] = this.arr2[index2];
            index2++;
          } else {
            index2 = 0;
            this.printArr[i] = this.arr2[index2];
            index2++;
          }
        } else {
          if (index1 < this.arr1.length) {
            this.printArr[i] = this.arr1[index1];
            index1++;
          } else {
            index1 = 0;
            this.printArr[i] = this.arr1[index1];
          }
        }
      }
      this.printArr = this.printArr.filter(function (x) {
        return x !== undefined;
      });
    },
  },
  async mounted() {
    const sliderId = this.$route.params.customerId;
    const { data } = await fetchSlideItems(sliderId);
    this.singleProfileInfo = data.user;
    if (!data.posts) {
      this.isSlideLoad = true;
      this.newSlides = true;
      this.fakeSpinner = false;
    }
    this.organizeSliders(data);
    this.isSlideLoad = true;
    this.newSlides = true;
    this.fakeSpinner = false;
    setTimeout(() => {
      const runNext = () => {
        let intervalTimer = 6000;
        (function () {
          let video = null;
          video = document.querySelectorAll(".vueperslide--active video")[0];
          if (video) {
            let videoDuration = video.duration * 1000;
            video.load();
            video.play();
            intervalTimer = videoDuration;
          } else {
            let nonActive = document.querySelectorAll(".videos-reset");
            nonActive.forEach((video) => {
              if (video.nodeName === "VIDEO") {
                video.pause();
                video.currentTime = 0;
              }
            });
          }
        })();
        setTimeout(() => {
          this.$refs.myVueperSlides.next(1);
          setTimeout(() => runNext(), 1000);
        }, intervalTimer);
      };
      runNext();
    }, 1000);
  },
  computed: {
    domainUrl() {
      return process.env.VUE_APP_MEDIA_DOMAIN;
    },
  },
});
</script>

<style scoped>
.fetch-slides--fake {
  background: linear-gradient(
    179.57deg,
    #0f2027 -5.06%,
    #203a43 48.48%,
    #2c5364 103.15%
  );
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  left: 0;
}
.fetch-slides--fake img {
  max-width: 100px;
  height: auto;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carousel__slide--active {
  -webkit-animation: fade-in 1s linear both;
  animation: fade-in 1s linear both;
}
.slide-wrapper--grid {
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  max-width: 1270px;
  margin: 0 auto;
  padding-top: 44px;
  padding-block: 83px;
  height: calc(100vh - 122px); */
  display: flex;
  max-width: 1270px;
  margin: 0 auto;
  padding-top: 40px;
}
.flex-box {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.main-slider {
  position: relative;
  /* grid-area: 1 / 1 / 6 / 4; */
  background-color: black;
  box-shadow: 6px 6px 21px 5px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.fixed-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.fixed-logo img {
  position: relative;
  margin: 0 auto;
  max-width: 130px;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
}
.fixed-logo::before {
  content: "";
  left: 0;
  width: 40%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  height: 1px;
}
.fixed-logo::after {
  content: "";
  right: 0;
  width: 40%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  height: 1px;
}
.small-slider {
  margin-bottom: 30px;
  /* max-width: 417px; */
  width: 100%;
  /* grid-area: 1 / 4 / 3 / 5; */
  height: 40%;
  background: linear-gradient(
    180deg,
    rgba(190, 190, 190, 0.12) 0%,
    rgba(132, 132, 132, 0.16) 60.38%
  );
  backdrop-filter: blur(16px);
  border-radius: 5px;
  padding: 21px 42px;
}
.small-slider .media-info-slider,
.small-slider .carousel {
  width: 100%;
  height: 100%;
}
.small-slider .carousel__slide {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.small-slider .carousel__slide img {
  width: 57px;
  height: 57px;
  background-color: black;
  border-radius: 50%;
  margin-bottom: 14px;
}
.small-slider .carousel__slide h2 {
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  font-family: PlusJakartaSans-SemiBold;
  color: white;
  margin-bottom: 47px;
}
.small-slider .carousel__slide p {
  font-family: PlusJakartaSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: white;
}
.hashtag-info-area {
  background: linear-gradient(
    180deg,
    rgba(190, 190, 190, 0.12) 0%,
    rgba(132, 132, 132, 0.16) 60.38%
  );
  backdrop-filter: blur(16px);
  /* grid-area: 3 / 4 / 6 / 5; */
  height: 60%;
  padding: 61px 44px 51px 44px;
  border-radius: 5px;
}

.hashtag-info-area h1 {
  font-family: PlusJakartaSans-ExtraBold;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  text-align: center;
  color: white;
}
.hashtag-info-area p {
  font-family: PlusJakartaSans-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: white;
  margin-top: 30px;
}
.hashtag-info-area h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  font-family: PlusJakartaSans-ExtraBold;
  text-align: center;
  color: white;
  margin-bottom: 0;
}
.main-slider .carousel,
.main-slider .carousel-slide {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.main-slider .carousel {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
}
.main-slider .carousel .carousel__viewport {
  width: 100%;
  height: 100%;
}
.main-slider .slider-image {
  height: calc(100vh - 160px);
}
.linear-bg {
  max-width: 100vw;
  min-height: 830px;
  background: linear-gradient(
    179.57deg,
    #0f2027 -5.06%,
    #203a43 48.48%,
    #2c5364 103.15%
  );
  width: 100%;
  height: 100vh;
  position: relative;
}
.created-by {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9;
}
.created-by p {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: white;
  font-family: PlusJakartaSans-Regular;
  margin: 0;
  margin-top: 5px;
}
.logo-wrapper {
  display: flex;
  justify-content: space-around;
}
.linear-header {
  background: rgba(29, 29, 29, 0.5);
  border-radius: 0px 0px 15px 15px;
  padding: 31px 82px;
  display: flex;
  justify-content: space-between;
}
.app-logo h1 {
  margin: 0;
  padding: 0;
  font-family: PlusJakartaSans-SemiBold;
  font-size: 48px;
  font-style: normal;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color: white;
}
.app-logo span {
  color: #f3ffd1;
  font-family: PlusJakartaSans-Bold;
}
.logo > img {
  max-width: 130px;
  width: 100%;
  max-height: 55px;
  height: 100%;
  object-fit: contain;
}
.slider-image {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.fetch-slides {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  z-index: 99;
}
.spinner {
  margin: auto;
}
.object-logo {
  object-fit: contain;
}

.logo {
  display: flex;
  align-items: center;
}
.logo > img {
  height: auto;
  max-height: 40px;
  margin-top: 0px;
}
.linear-header {
  padding: 0px 15px;
}
.app-logo h1 {
  font-size: 20px;
}
.slide-wrapper--grid {
  padding: 10px 15px 0px 15px;
}
.main-slider {
  flex: 1 1 70%;
  height: calc(100vh - 98px);
}
.flex-box {
  flex: 1 1 30%;
  width: 30%;
  margin-left: 15px;
}
.main-slider .slider-image {
  height: 90vh;
}
.small-slider {
  padding: 12px;
}
.hashtag-info-area {
  padding: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.hashtag-info-area h2 {
  font-size: 25px;
}
.small-slider .carousel__slide h2 {
  margin-bottom: 18px !important;
}
.hashtag-info-area h1 {
  font-size: 25px;
  line-height: 30px;
}
.hashtag-info-area p {
  font-size: 17px;
  margin-top: 10px;
  line-height: normal;
  margin-bottom: 0;
}
.linear-bg {
  padding-bottom: 0px;
  min-height: auto;
}
.created-by {
  display: flex;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  right: auto;
}
.small-slider {
  margin-bottom: 15px;
}
.created-by p {
  width: 100%;
  text-align: center;
  font-size: 13px;
  margin-top: 5px;
}
</style>
