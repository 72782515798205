export const managerSlider = {
  state: {
    sliders: [],
    blockedImages: [],
  },
  mutations: {
    ADD_IMAGE_TO_SLIDER(state, image) {
      state.sliders.push(image, image);
    },
    ADD_IMAGE_TO_BLOCKED_LIST(state, image) {
      state.blockedImages.push(image);
    },
  },
  actions: {
    addImageToSlider({ commit }, image) {
      commit("ADD_IMAGE_TO_SLIDER", image);
    },
    addImageToBlockedList({ commit }, image) {
      commit("ADD_IMAGE_TO_BLOCKED_LIST", image);
    },
  },
};
