<template>
  <app-header></app-header>
  <admin v-if="$route.params.role === 'administrator'"></admin>
  <manager v-else></manager>
  <app-footer></app-footer>
</template>

<script>
import Admin from "../components/roles/Admin.vue";
import Manager from "../components/roles/Manager.vue";
import AppHeader from "../layout/Header.vue";
import AppFooter from "../layout/Footer.vue";

export default {
  components: {
    Admin,
    Manager,
    AppFooter,
    AppHeader,
  },
};
</script>
