<template>
  <router-view />
</template>
<script>
import jwt_decode from "jwt-decode";

export default {
  mounted() {
    document.title = "SocialFeed v2";
    const isUserLoggedIn = this.$cookies.get(
      process.env.VUE_APP_LOGIN_COOKIE_NAME
    );
    if (isUserLoggedIn) {
      this.$store.dispatch("loginUser");
      this.$store.dispatch("setAuthToken", isUserLoggedIn);
      const decoded = jwt_decode(isUserLoggedIn);
      this.$store.dispatch("setCurrentUserRole", decoded.role);
      this.$router.push(`/dashboard/${decoded.role}`);
    }
  },
};
</script>
<style>
.vue-core-video-player-layers,
.vcp-controls,
.vcp-dashboard {
  display: none;
}
.main-slider .vueperslides,
.main-slider .vueperslides__inner,
.main-slider .vueperslides__parallax-wrapper {
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}
#app {
  min-width: 100vw;
  min-height: 100vh;
}
@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: local("PlusJakartaSans-Bold"),
    url("./assets/fonts/PlusJakartaSans-Bold.woff") format("woff");
}
@font-face {
  font-family: "PlusJakartaSans-SemiBold";
  src: local("PlusJakartaSans-SemiBold"),
    url("./assets/fonts/PlusJakartaSans-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "PlusJakartaSans-ExtraBold";
  src: local("PlusJakartaSans-ExtraBold"),
    url("./assets/fonts/PlusJakartaSans-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: local("PlusJakartaSans-Regular"),
    url("./assets/fonts/PlusJakartaSans-Regular.woff") format("woff");
}
</style>
