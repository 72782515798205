import axios from "axios";
const getCookieValue = (name) =>
  document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() || "";
export const loginUser = async (email, password) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_ENDPOINT}/auth/login`,
      {
        email,
        password,
      }
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const loadUsers = async (token) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const users = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/auth/users`,
      config
    );
    return users;
  } catch (error) {
    throw new Error(error);
  }
};
export const createNewUser = async (newUser) => {
  try {
    const response = await axios.post(
      `${process.env.VUE_APP_API_ENDPOINT}/auth/register`,
      newUser
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const loadMoreusers = async (token, page) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/auth/users?page=${page}`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const createSliderImage = async (formDataOut) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const formData = new FormData();
    formData.append("featured_image", formDataOut.featured_image);
    formData.append("status_id", 2);
    formData.append("repeat", formDataOut.repeat);
    const response = await axios.post(
      `${process.env.VUE_APP_API_ENDPOINT}/posts`,
      formData,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchInstagramFeed = async () => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/posts/instagram-feed`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchLocalFeed = async () => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/posts/local-feed`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateSelectImageStatus = async (
  id,
  status,
  featured_image_id = null,
  repeat
) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  if (status === false) {
    status = 1;
  } else {
    status = 2;
  }
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response;
    if (featured_image_id !== null) {
      response = await axios.put(
        `${process.env.VUE_APP_API_ENDPOINT}/posts/${id}`,
        { status_id: status, featured_image_id, repeat },
        config
      );
    } else {
      response = await axios.put(
        `${process.env.VUE_APP_API_ENDPOINT}/posts/${id}`,
        { status_id: status },
        config
      );
    }

    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchMoreInstagramFeed = async (page) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/posts/instagram-feed?page=${page}`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const updateImageRepeat = async (id, position, imgId) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.put(
      `${process.env.VUE_APP_API_ENDPOINT}/posts/${id}`,
      { status_id: 2, repeat: position, featured_image_id: imgId },
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const deletePost = async (postId) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.delete(
      `${process.env.VUE_APP_API_ENDPOINT}/posts/${postId}`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const deleteUser = async (userId) => {
  const token = getCookieValue(process.env.VUE_APP_LOGIN_COOKIE_NAME);
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await axios.delete(
      `${process.env.VUE_APP_API_ENDPOINT}/auth/user/delete/${userId}`,
      config
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const fetchSlideItems = async (userId) => {
  try {
    const response = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/client/posts/${userId}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
