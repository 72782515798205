<template>
  <div class="container">
    <h1 class="mx-auto my-5 text-center">404! Stranica nije pronadjena!</h1>
    <router-link to="/login" class="d-flex mx-auto">
      <CButton color="primary" class="mx-auto" size="lg"
        >Login stranica</CButton
      >
    </router-link>
  </div>
</template>

<script>
import { CButton } from "@coreui/vue";
export default {
  components: {
    CButton,
  },
};
</script>
